<template>
    <content-wrapper
        :breadcrumbs="breadcrumbs"
        comeback-previous
    >
        <group-preview
            v-if="item"
            :item="item"
        >
            <template v-if="isMobile" #actions>
                <w-actions-menu
                    icon="MENU"
                    :items="menu"
                />
            </template>
        </group-preview>
        <w-toolbar-menu
            v-if="!isMobile"
            :items="menu"
            height="52"
            divider
            underlined
            outlined
            class="mt-2"
        />
        <div class="mt-2">
            <router-view></router-view>
        </div>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import { getGroup } from '@apps/school/api/groups'
import GroupPreview from '@apps/school/components/Group/GroupPreview'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
export default {
    name: 'GroupDetails',
    mixins: [ mobileBreakpointChecker ],
    components: { GroupPreview, ContentWrapper },
    props: {
        uuid: String
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Groups'),
                    to: {name: 'groups'}
                },
                {
                    text: this.item ? this.item.title : null
                }
            ]
        },
        menu() {
            return [
                {
                    text: this.$trans('Students'),
                    icon: 'STUDENT',
                    to: {
                        name: 'groups.students',
                        params: { uuid: this.uuid }
                    }
                },
                {
                    text: this.$trans('Tests'),
                    icon: 'QUESTIONS',
                    to: {
                        name: 'groups.questions.shared',
                        params: { uuid: this.uuid }
                    }
                },
                {
                    text: this.$trans('Results of tests'),
                    icon: 'CHECK',
                    to: {
                        name: 'groups.questions.statistic',
                        params: { uuid: this.uuid }
                    }
                },
                {
                    text: this.$trans('Day book'),
                    icon: 'DAYBOOK',
                    to: {
                        name: 'groups.daybooks',
                        params: { uuid: this.uuid }
                    }
                }
            ]
        }
    },
    data: () => ({
        item: null
    }),
    mounted() {
        this.fetchGroup()
    },
    methods: {
        async fetchGroup() {
            this.$lprogress.begin()

            try {
                const payload = {
                    with: 'level.school'
                }

                const response = await getGroup(this.uuid, payload)
                this.item = response.data.data
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
